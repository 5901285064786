jQuery(function($){

    $('.js-icon-carousel').slick({
        autoplay: false,
        autoplaySpeed: 0,
        centerMode: true,
        cssEase: 'cubic-bezier(0, 0, 0.04, 0.98)',
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false,
        pauseOnHover: false,
        rows: 0,
        dots: true,
        appendDots: $(".js-icon-card-dots"),
    });

    $('.js-icon-carousel-next').click(function(e) {
        e.preventDefault();
        $(this).parents('.block-icon-carousel').find('.js-icon-carousel').slick('slickNext');
    });
    $('.js-icon-carousel-prev').click(function(e) {
        e.preventDefault();
        $(this).parents('.block-icon-carousel').find('.js-icon-carousel').slick('slickPrev');
    });

});